import React, {useContext} from "react";
import { useNavigate } from "react-router-dom";
import { UserContext, useUpdateUser, useUser } from "../../Context/UserContext";
import Heart from "../Svgs/Heart";
import Trash from "../Svgs/Trash";
import { IoIosHome } from "react-icons/io";
const FirstSOVFullImage = "/images/House.avif";

{/* <FontAwesomeIcon icon="fa-solid fa-house" /> */}
const AkiyaCardFavoriteSection = ({ item, isFavorite = false, onDelete }) => {
  const {
    Images,
    Address,
    Area_of_House,
    Area_of_Land,
    Price,
    property_id,
  } = item;

  const navigate = useNavigate();
  const user = useUser();
  const UpdateUser = useUpdateUser();
  const { setShowModal } = useContext(UserContext);

  // Derived values
  const cleanedPrice = Price ? Price.replace(/[^\d]/g, "") : "";
  const formattedPrice =
    cleanedPrice && cleanedPrice > 10000
      ? `¥ ${Number(cleanedPrice).toLocaleString()}`
      : "Consult with administrator";

  const formattedAreaOfLand = Area_of_Land
    ? `${Area_of_Land.replace(/\s|m/g, "")}m²`
    : "N/A";
  const formattedAreaOfHouse = Area_of_House
    ? `${Area_of_House.replace(/\s|m/g, "")}m²`
    : "N/A";

  const isFavoritePresent =
    user && user.favorite && user.favorite.includes(property_id);

  // Event Handlers
  const handleToggleFavorite = (event) => {
    event.stopPropagation();
    if (!user) {
      setShowModal(true);
    } else {
      const favoriteData = isFavoritePresent
        ? user.favorite.filter((f) => f !== property_id)
        : [...user.favorite, property_id];
      UpdateUser({ ...user, favorite: favoriteData });
    }
  };

  const firstImage = Images && Images.length > 0 ? Images[0] : null;

  return (
    <div
      className="flex overflow-hidden transition-shadow duration-300 border border-gray-200 rounded-lg shadow-md cursor-pointer hover:shadow-lg"
      onClick={() => navigate(`/individual-akiya/${property_id}`)}
    >
      {/* Image Section */}
      <div className="relative w-1/3">
        {firstImage ? (
          <img
            loading="lazy"
            className="object-cover w-full h-full"
            src={firstImage}
            alt="Akiya"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop if placeholder image fails
              e.target.src = FirstSOVFullImage; // Display placeholder image
            }}
          />
        ) : (
          <div className="flex items-center justify-center w-full h-full text-center bg-gray-200">
            <p className="text-sm text-gray-500">No images available</p>
          </div>
        )}
        {!isFavorite && (
          <button
            onClick={handleToggleFavorite}
            className={`absolute top-2 right-2 ${
              isFavoritePresent ? "text-red-500" : "text-gray-400"
            }`}
          >
            <Heart />
          </button>
        )}
      </div>

      {/* Text Section */}
      <div className="flex flex-col justify-between w-2/3 p-4">
        <div>
          {/* Address */}
          <p className="mb-1 text-sm text-gray-600">Address:</p>
          <p className="mb-3 text-sm font-semibold text-gray-800">
            {Address ? Address.slice(0, 90) : "No address available"}
          </p>

          {/* Floor Area */}
          <p className="mb-1 text-sm text-gray-600">Floor Area:</p>
          <p className="mb-3 text-sm text-gray-800">{formattedAreaOfLand}</p>

          {/* House Area */}
          <p className="mb-1 text-sm text-gray-600">House Area:</p>
          <p className="mb-3 text-sm text-gray-800">{formattedAreaOfHouse}</p>
        </div>

        {/* Price */}
        <div className="text-right text-lg font-bold text-[#5AB963]">
          {formattedPrice}
        </div>

        {/* Delete button if Favorite */}
        {isFavorite && (
          <button
            className="px-3 py-1 mt-4 text-red-500 bg-red-100 rounded-lg"
            onClick={(event) => {
              event.stopPropagation();
              onDelete(property_id);
            }}
          >
            <Trash />
          </button>
        )}
      </div>
    </div>
  );
};

export default AkiyaCardFavoriteSection;