import React from "react";
import HeadingForAll from "../HeadingForAll/HeadingForAll";
import Sidebar from "../Sidebar/Sidebar";
import ProfileCard_AccountSettings from "../ProfileCard/ProfileCard"
import { useUpdateUser, useUser } from "../../Context/UserContext";
import { fetchData } from "../../Utils/CommonFunctions";



// <<<<<<< main
// function InputField({ label, name, value, type, onChange }) {
//   return (
//     <div className="">
//       <label>{label}:</label>
//       <input
//         className="w-full mt-1 h-12 border-[1px] rounded-lg text-md text-[--dark-grey-2] placeholder-sm text-sm
//           outline-none focus:outline-non pl-2"
//         type={type}
//         name={name}
//         value={value}
//         onChange={onChange}
//       />
//     </div>
//   );
// }

// function ProfileSetting() {
//   const [userData, setUserData] = useState();
//   const user = useUser();
//   const navigate = useNavigate();
//   const fileInputRef = useRef(null); // Reference to the hidden file input element
//   const [userImage, setUserImage] = useState();
//   const [imageUrl, setImageUrl] = useState(null);
//   const updateUserImage = useUpdateUserImage();

//   useEffect(() => {
//     if (user) {
//       setUserData(user);
//       // console.log(user.image_url);
//     }
//   }, [user]);

//   const updateUser = useUpdateUser();

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setUserData((prevUserData) => ({
//       ...prevUserData,
//       [name]: value,
//     }));
//   };
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     await updateUser(userData);
//     await updateUserImage(userImage);
// =======
const ProfileSetting = () => {

  
  return(
    <div className="relative">
  <HeadingForAll bigHeading={"My Profile"} isProfile={true} />

  <div className="flex justify-center mt-4">
        <div className="w-full max-w-[1440px]">
          <div className="flex flex-col md:flex-row">
          <div className="flex flex-col items-center w-full gap-2 md:w-1/3">
            <Sidebar />
          </div>
          <div className="flex flex-col w-full gap-8 md:w-2/3">
              <ProfileCard_AccountSettings />
            </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileSetting;