import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./individualProperty.css";

const placeholderImage = "/images/MainLogo.webp";

function Gallery({ images, video }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [visibleThumbnails, setVisibleThumbnails] = useState(3);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const [thumbnailLoaded, setThumbnailLoaded] = useState(
    Array(images.length).fill(false)
  );
  const thumbnailsRef = useRef(null);
  const sliderRef = useRef(null);
  const thumbnailRefs = useRef([]); // Array of refs for thumbnails

  // Update visible thumbnails based on window width
  useEffect(() => {
    const updateVisibleThumbnails = () => {
      if (window.innerWidth >= 1200) {
        setVisibleThumbnails(9);
      } else if (window.innerWidth >= 1024) {
        setVisibleThumbnails(7);
      } else if (window.innerWidth >= 768) {
        setVisibleThumbnails(5);
      } else if (window.innerWidth >= 640) {
        setVisibleThumbnails(4);
      } else {
        setVisibleThumbnails(3);
      }
    };
    updateVisibleThumbnails();
    window.addEventListener("resize", updateVisibleThumbnails);
    return () => window.removeEventListener("resize", updateVisibleThumbnails);
  }, []);

  // Show scroll indicator if thumbnails overflow
  useEffect(() => {
    if (images.length > visibleThumbnails) {
      setShowScrollIndicator(true);
      const interval = setInterval(() => {
        setShowScrollIndicator(true);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [images.length, visibleThumbnails]);

  const handleScroll = () => {
    if (images.length > visibleThumbnails) {
      setShowScrollIndicator(false);
    }
  };

  const changeImage = (index) => {
    setCurrentImageIndex(index);
    sliderRef.current.slickGoTo(index);
    // Scroll the thumbnail into view when clicked
    if (thumbnailRefs.current[index]) {
      thumbnailRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  const handleThumbnailLoad = (index) => {
    setThumbnailLoaded((loaded) =>
      loaded.map((value, i) => (i === index ? true : value))
    );
  };

  // Settings for the main image slider
  const mainSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // We use custom arrows
    swipe: true,
    adaptiveHeight: true, // Adjust slider height based on image height
    beforeChange: (current, next) => {
      setCurrentImageIndex(next);
      // Scroll the thumbnail into view
      if (thumbnailRefs.current[next]) {
        thumbnailRefs.current[next].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    },
  };

  return (
    <div className="flex flex-col items-center justify-center w-full sm:w-11/12 md:w-10/12 lg:w-10/12 mx-auto mt-8">
      {/* Main Image Slider */}
      <div className="relative w-full">
        {/* Remove overflow-hidden from this div */}
        <button
          className="invisible md:visible prev-btn absolute top-1/2 transform -translate-y-1/2 left-[-2rem] text-white bg-[#5ab963] opacity-75 rounded-full p-2 hover:opacity-100 hover:pr-6 hover:-translate-x-1 transition-all text-lg sm:text-xl z-20"
          onClick={() => sliderRef.current.slickPrev()}
        >
          &#10094;
        </button>

        <Slider ref={sliderRef} {...mainSliderSettings}>
          {images.map((image, index) => (
            <div key={index} className="w-full">
              <img
                className="h-[100%] w-full max-h-[70vh] min-h-[300px] object-cover"
                loading="lazy"
                src={image}
                alt={`Akiya2.0 property ${index}`}
                onLoad={() => handleThumbnailLoad(index)}
              />
            </div>
          ))}
        </Slider>

        <button
          className="invisible md:visible next-btn absolute top-1/2 transform -translate-y-1/2 right-[-2rem] text-white bg-[#5ab963] opacity-75 rounded-full p-2 hover:opacity-100 hover:pl-6 hover:translate-x-1 transition-all text-lg sm:text-xl z-20"
          onClick={() => sliderRef.current.slickNext()}
        >
          &#10095;
        </button>
      </div>

      {/* Thumbnails Section */}
      <div className="w-full mt-4 relative">
        <div
          className="flex overflow-x-auto space-x-2 thumbnails"
          onScroll={handleScroll}
          ref={thumbnailsRef}
          style={{
            width: "100%",
          }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              ref={(el) => (thumbnailRefs.current[index] = el)} // Assign ref to each thumbnail
              className="flex-shrink-0 cursor-pointer"
              style={{
                width: `calc(100% / ${visibleThumbnails} - 0.5rem)`,
              }}
              onClick={() => changeImage(index)}
            >
              <img
                loading="lazy"
                src={thumbnailLoaded[index] ? image : placeholderImage}
                alt={`Akiya2.0 property thumbnail ${index}`}
                className={`w-full h-20 object-cover rounded ${
                  index === currentImageIndex ? "border-4 border-[#5ab963]" : ""
                }`}
                onLoad={() => handleThumbnailLoad(index)}
              />
            </div>
          ))}
        </div>

        {/* Scroll Indicator or Gradient Overlay if needed */}
        {images.length > visibleThumbnails && showScrollIndicator && (
          <div className="absolute top-0 right-0 h-full w-12 pointer-events-none bg-gradient-to-l from-white"></div>
        )}
      </div>

      {/* Video Section */}
      {video && (
        <div className="w-full mt-8 flex justify-center">
          <div className="video-responsive">
            <div
              dangerouslySetInnerHTML={{ __html: video }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Gallery;