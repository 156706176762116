import React from "react";
import { Link } from "react-router-dom";

const japanTravel = "/images/about/japantravel.webp";
const o2ostays = "/images/about/o2ostays.webp";
const suzu = "/images/about/suzu.webp";
const wajima = "/images/about/wajima.webp";
const noto = "/images/about/noto.webp";
const kominka = "/images/about/kominka.png";
const kominka1 = "/images/about/kominka1.webp";
const imabari = "/images/about/imabari.webp";
const kankensetsu = "/images/about/kankensetsu.webp";
function ThirdSOV() {
  const head = "Introducing Our Partners.";
  const para = (
    <>
      We work closely alongside Japanese city/prefectural governments partner
      agencies in Japan.
      <br />
      As an outward facing Travel Club operator, we're open to partnerships with
      overseas travel businesses around the world.
    </>
  );

  return (
    <div>
      <h2 className="dark-header mb-4">{head}</h2>
      <p className="light-header mb-8">{para}</p>

      <div className="flex flex-wrap justify-center items-center">
        <div className="w-full sm:w-1/3 p-2 flex justify-center items-center">
          <Link className="w-[70%] h-auto" to="https://en.japantravel.com/" target="_blank">
            <img src={japanTravel} loading="lazy" alt="japantravel" className="h-auto" />
          </Link>
        </div>
        <div className="w-full sm:w-1/3 p-2 flex justify-center items-center">
          <Link className="w-[100%] h-auto" to="https://o2ostays.co/" target="_blank">
            <img src={o2ostays} loading="lazy" alt="o2ostays" className="h-auto" />
          </Link>
        </div>
      </div>


      <div className="flex flex-wrap justify-center items-center">

      <div className="w-full sm:w-1/3 p-2 flex justify-center items-center">
          <Link className="w-[40%] h-auto" to="https://www.sekikensetsu.com/" target="_blank">
            <img src={kankensetsu} loading="lazy" alt="kankensetsu" className="h-auto" />
          </Link>
        </div>
        
        <div className="w-full sm:w-1/3 p-2 flex justify-center items-center">
          <Link className="w-[90%] h-auto" to="http://www.g-cpc.org/" target="_blank">
            <img src={kominka} loading="lazy" alt="Kominka" className="h-auto" />
          </Link>
        </div>

        
        <div className="w-full sm:w-1/3 p-2 flex justify-center items-center">
          <Link className="w-[40%] h-auto" to="http://kominka.net/" target="_blank">
            <img src={kominka1} loading="lazy" alt="Kominka1" className="h-auto" />
          </Link>
        </div>


      </div>

      <div className="flex flex-wrap justify-center items-center">
        <div className="w-full sm:w-1/4 p-2">
          <Link className="w-[50%] h-auto" to="https://www.city.suzu.lg.jp.e.er.hp.transer.com/" target="_blank">
            <img src={suzu} loading="lazy" alt="Suzu" className="w-full sm-[50%] h-auto" />
          </Link>
        </div>
        <div className="w-full sm:w-1/4 p-2">
          <Link className="w-[50%] h-auto" to="https://www.city.imabari.ehime.jp/" target="_blank">
            <img src={imabari} loading="lazy" alt="Imabari" className="w-full h-auto" />
          </Link>
        </div>
        <div className="w-full sm:w-1/4 p-2">
          <Link className="w-[50%] h-auto" to="https://www.city.wajima.ishikawa.jp/" target="_blank">
            <img src={wajima} loading="lazy" alt="Wajima" className="w-full h-auto" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ThirdSOV;
