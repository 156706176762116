import React from "react";
import { HashLink } from "react-router-hash-link";

function QuestionBox2Rows() {
  return (
    <div className="flex flex-col items-center my-5 text-center">
      {/* Text Section */}
      <div className="w-full mb-3">
        <h2 className="text-[24px] font-[800] mb-3">Have a question?</h2>
        <h3 className="text-[#949995] text-[24px] font-[300] leading-[36px]">
          Feel free to get in touch with us!
        </h3>
      </div>

      {/* Button Section */}
      <div className="flex justify-center w-full mt-5">
        <HashLink smooth to="/about-us/#form">
          <button className="w-auto md:rounded-md bg-[#5ab963] px-6 py-4 text-white text-lg font-bold">
            Contact Us
          </button>
        </HashLink>
      </div>
    </div>
  );
}

export default QuestionBox2Rows;
