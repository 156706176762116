import React, { useEffect, useState } from "react";
import HeadingForAll from "../HeadingForAll/HeadingForAll";
import { useUpdateUser, useUser } from "../../Context/UserContext";
import { fetchData } from "../../Utils/CommonFunctions";
import AkiyaCardFavoriteSection from "../AkiyaCard/AkiyaCardFavoriteSection";
import CustomButt from "../CustomButton/CustomButt";
import { useNavigate } from "react-router-dom";
import Twitter from "../Svgs/Twitter";
import LinkedIn from "../Svgs/LinkedIn";
import Facebook from "../Svgs/Facebook";
import QuestionBox2Rows from "../QuestionBox/QuestionBox2Rows";
import cheerio from "cheerio";
import BlogCard from "../blogCard/BlogCard";
import ProfileBLogCard from "../blogCard/ProfileBlogCard";
import Sidebar from "../Sidebar/Sidebar";

const userImg = "/images/OtherImages/user.png";

const ProfileMain = () => {
  const user = useUser();
  const updateUser = useUpdateUser();
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState([]);
  const [blogs, setBlogs] = useState([]);
  if (!user) {
  }

  const {
    image_url,
    name,
    email,
    about_them,
    favorite,
    linkedin_link,
    twitter_link,
    blog,
  } = user;

  useEffect(() => {
    const { favorite } = user;
    if (favorite) {
      let tempData = [];
      const fetchPromises = []; // Array to store all fetch promises

      for (let id of favorite) {
        const promise = fetchData(
          `https://app.akiya2.com/one_listing/${id}`
        ).then((data) => {
          tempData.push(data);
        });
        fetchPromises.push(promise); // Store the promise in the array
      }

      // Wait for all promises to resolve
      Promise.all(fetchPromises).then(() => {
        setFavorites(tempData);
      });
    }
  }, [user]);
  useEffect(() => {
    if (blog) {
      let tempData = [];

      const fetchPromises = []; // Array to store all fetch promises

      for (let index of blog) {
        const id = index;
        const promise = fetchData(`https://app.akiya2.com/blog/${index}`)
          .then((data) => {
            // Extracting necessary information from the fetched data
            const {
              author,
              createdAt,
              blog_heading,
              blog_content,
              coverImage,
              index,
            } = data;

            // Load HTML content into cheerio
            const $ = cheerio.load(blog_content);
            const formattedDate = new Date(createdAt).toLocaleDateString(
              "en-US",
              {
                month: "long",
                day: "numeric",
                year: "numeric",
              }
            );

            // Extract content limited to 60 words from the first <p> tag
            const content = $("p")
              .first()
              .text()
              .split(" ")
              .slice(0, 30)
              .join(" ");

            // Push data to tempData
            tempData.push({
              author,
              date: formattedDate,
              title: blog_heading,
              content,
              imageUrls: coverImage,
              index: id,
            });
          })
          .catch((error) => {
            console.error("Error fetching blog data:", error);
          });

        fetchPromises.push(promise); // Store the promise in the array
      }

      // Wait for all promises to resolve
      Promise.all(fetchPromises).then(() => {
        // console.log(tempData);
        setBlogs(tempData);
      });
    }
  }, [blog]);

  const handleDelete = (id) => {
    // console.log("delete clicked");
    // console.log(favorite);
    const tempFavorites = favorite.filter((f) => f !== id);
    // console.log(tempFavorites);

    const updatedUser = { ...user, favorite: tempFavorites };
    // console.log(updatedUser);
    updateUser(updatedUser);
  };

  return (
        <div className="relative">
          <HeadingForAll bigHeading={"My Profile"} isProfile={true} />
      
          <div className="flex justify-center mt-4">
            <div className="max-w-[1440px] w-[100vw] mx-[1rem]">
              <div className="flex flex-col md:flex-row">
                {/* Sidebar with QuestionBox below */}
                <div className="flex flex-col items-center w-full gap-4 md:w-1/3">
                  <Sidebar />
                  <div className="flex justify-center w-full">
                    <QuestionBox2Rows />
                  </div>
                </div>
      
                {/* Main Content: Favorites and Blogs */}
                <div className="flex flex-col w-full gap-8 md:w-2/3">
                  {/* Favorites Section */}
                  <div>
                    <div className="px-3 py-5 text-2xl font-bold border">
                      My Favorites
                    </div>
                    <div>
                      {favorites.length ? (
                        <div className="flex flex-col gap-6 mt-4">
                          {favorites.map((item) => (
                            <AkiyaCardFavoriteSection
                              key={item.property_id}
                              item={item}
                              isFavorite={true}
                              onDelete={handleDelete}
                            />
                          ))}
                        </div>
                      ) : (
                        <div className="inline-flex items-center w-full gap-4 pl-2 mt-10 mb-5">
                          <p className="text-3xl">Nothing on Favorites:</p>
                          <CustomButt
                            text={"Add to Favorites"}
                            handleButtonClick={() => navigate("/akiya-search")}
                          />
                        </div>
                      )}
                    </div>
                  </div>
      
                  {/* Blogs Section */}
                  <div>

                  <div className="text-2xl font-bold px-3 py-5 border flex justify-between items-center ">
                My blogs
                {blogs.length ? (
                  <button
                    onClick={() => navigate("/blog-writing")}
                    className={`custom-button bg-[var(--medium-sea-green)] hover:bg-[var(--sea-green)] inline-block  text-white font-semibold py-2 px-4 rounded-lg text-lg`}
                  >
                    <span>Add a blog</span>
                  </button>
                ) : null}
              </div>
              <div className="min-h-screen">
              {blog.length ? (
                <div className="mt-4 mb-10 grid xl:grid-cols-3 sm:grid-cols-2 py-3 gap-8 overflow-y-auto">
                  {blogs.map((item) => (
                    <ProfileBLogCard key={item.index} item={item} />
                  ))}
                </div>
              ) : (
                <div className="w-full pl-2 mt-10 inline-flex gap-4 items-center">
                  <p className="text-3xl">Create your first blog:</p>
                  <CustomButt
                    text={"Add Blog"}
                    handleButtonClick={() => navigate("/blog-writing")}
                  />
                </div>
              )}
            </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
};

export default ProfileMain;

