import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext, useUpdateUser, useUser } from "../../Context/UserContext";
import Heart from "../Svgs/Heart";
import Trash from "../Svgs/Trash";
const FirstSOVFullImage = "/images/MainLogo.webp";

const AkiyaCard = ({ item, isFavorite = false, onDelete }) => {
  const { Images, Address, Area_of_House, Area_of_Land, Price, property_id } = item;

  const navigate = useNavigate();
  const user = useUser();
  const UpdateUser = useUpdateUser();
  const { setShowModal } = useContext(UserContext);
  const [imageSrc, setImageSrc] = useState(null); // For lazy-loaded image source
  const imgRef = useRef(null); // Reference to the image element for intersection observer

  // Derived values
  const cleanedPrice = Price ? Price.replace(/[^\d]/g, "") : "";
  const formattedPrice = cleanedPrice && cleanedPrice > 10000
    ? `¥ ${Number(cleanedPrice).toLocaleString()}`
    : "Consult with administrator";

  const formattedAreaOfLand = Area_of_Land ? `${Area_of_Land.replace(/\s|m/g, "")}m²` : "N/A";
  const formattedAreaOfHouse = Area_of_House ? `${Area_of_House.replace(/\s|m/g, "")}m²` : "N/A";

  const isFavoritePresent = user && user.favorite && user.favorite.includes(property_id);

  // Lazy load the image using Intersection Observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setImageSrc(Images && Images.length > 0 ? Images[0] : FirstSOVFullImage); // Set the actual image source
            observer.unobserve(entry.target); // Stop observing once image is loaded
          }
        });
      },
      { threshold: 0.1 }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [Images]);

  // Event Handlers
  const handleToggleFavorite = (event) => {
    event.stopPropagation();
    if (!user) {
      setShowModal(true);
    } else {
      const favoriteData = isFavoritePresent
        ? user.favorite.filter((f) => f !== property_id)
        : [...user.favorite, property_id];
      UpdateUser({ ...user, favorite: favoriteData });
    }
  };

  return (
    <div
      className="shadow-xl h-[32rem] w-full cursor-pointer relative group overflow-hidden transition-transform duration-300 transform hover:scale-105"
      onClick={() => navigate(`/individual-akiya/${property_id}`)}
    >
      <div className="h-[40%] w-full relative">
        {!isFavorite && (
          <div className="absolute right-0 flex w-[4rem] justify-end h-[3rem] opacity-100">
            <button
              onClick={handleToggleFavorite}
              className={`${isFavoritePresent ? "text-[#5AB963]" : "text-white"} px-2 m-1 stroke-[#5AB963]`}
            >
              <div className="w-10 h-10">
                <Heart />
              </div>
            </button>
          </div>
        )}

        <div className="absolute bottom-0 bg-black/60 w-full h-[3rem] text-white flex justify-center items-center">
          {formattedPrice}
        </div>


        <img
          loading="lazy"
          ref={imgRef} // Reference for Intersection Observer
          className="h-full w-full"
          src={imageSrc || FirstSOVFullImage} // Load placeholder until image is in view
          alt="Akiya"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = FirstSOVFullImage; // Fallback image on error
          }}
        />

{/* //         {firstImage ? (
//           <img
//             loading="lazy"
//             className="w-full h-full"
//             src={firstImage}
//             alt="Akiya"
//             onError={(e) => {
//               e.target.onerror = null; // Prevent infinite loop if placeholder image fails
//               e.target.src = FirstSOVFullImage; // Display placeholder image
//             }}
//           />
//         ) : (
//           <div className="flex items-center justify-center w-full h-full text-center bg-gray-200">
//             <p className="text-sm text-gray-500">No images available</p>
//           </div>
//         )} */}

      </div>

      <div className="flex flex-col gap-6 mx-2 my-5">
        <div>
          <p className="mb-2">Address:</p>
          {Address && <p className="text-[#333333] text-sm">{Address.slice(0, 90)}</p>}
        </div>
        <div>
          <p className="mb-2">Floor Area:</p>
          <p className="text-[#333333] text-sm">{formattedAreaOfLand}</p>
        </div>
        <div>
          <p className="mb-2">House Area:</p>
          <p className="text-[#333333] text-sm">{formattedAreaOfHouse}</p>
        </div>
        {isFavorite && (
          <button
            className="bg-red-100 rounded-xl absolute bottom-2 right-[40%]"
            onClick={(event) => {
              event.stopPropagation();
              onDelete(property_id);
            }}
          >
            <Trash />
          </button>
        )}
      </div>
    </div>
  );
};

export default AkiyaCard;