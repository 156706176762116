export default function ProfileAvatar() {
    return(
        <div className="p-6 bg-white rounded-lg shadow-md">
  <h2 className="text-2xl font-semibold text-gray-800">Profile avatars</h2>

  {/* Info Message */}
  <div className="p-4 my-4 bg-gray-100 border border-gray-200 rounded-md">
    <p className="text-sm text-gray-700">
      By uploading a profile photo or cover photo to this site, you agree that you have the right to use such photos, no external attribution is required and that they abide by our{" "}
      <a href="#" className="text-red-600 underline">Member Agreement</a>.
    </p>
  </div>

  <hr className="my-4 border-gray-300" />

  {/* Profile Avatar Section */}
  <div className="mb-6">
    <h3 className="text-lg font-medium text-gray-800">Profile avatar</h3>
    <p className="text-sm text-gray-600">Upload or drag-and-drop your photo! Recommended minimum size: 240x240</p>
    <div className="flex flex-col items-center mt-4">
      <div className="flex items-center justify-center w-24 h-24 bg-gray-200 rounded-full">
        {/* Placeholder for avatar */}
        <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-500" viewBox="0 0 24 24" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M12 12a5 5 0 100-10 5 5 0 000 10zM4 18a8 8 0 1116 0H4z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="flex mt-4 space-x-4">
        <button className="text-sm text-red-600 hover:underline">Edit Photo</button>
        <button className="text-sm text-red-600 hover:underline">Delete Photo</button>
      </div>
    </div>
  </div>

  {/* Cover Photo Section */}
  <div className="mb-6">
    <h3 className="text-lg font-medium text-gray-800">Cover photo</h3>
    <p className="text-sm text-gray-600">Upload or drag-and-drop your photo! Recommended minimum size: 2400x600</p>
    <div className="mt-4">
      <div className="w-full h-40 rounded-md bg-gradient-to-r from-green-400 to-green-600"></div>
      <div className="flex mt-4 space-x-4">
        <button className="text-sm text-red-600 hover:underline">Edit Photo</button>
        <button className="text-sm text-red-600 hover:underline">Delete Photo</button>
      </div>
    </div>
  </div>

  {/* Save Button */}
  <div className="flex justify-end">
    <button className="px-6 py-2 text-white bg-green-600 rounded-md hover:green-red-700">Save</button>
  </div>
</div>

    )
}

;