import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useUpdateUser, useUser, useUpdateUserImage } from "../../Context/UserContext";

const userImg = "/images/OtherImages/user.png";

export default function ProfileCardAccountSettings() {
  const user = useUser();
  const updateUser = useUpdateUser();
  const updateUserImage = useUpdateUserImage();
  const fileInputRef = useRef(null);

  const [userData, setUserData] = useState({});
  const [userImage, setUserImage] = useState();
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (user) {
      setUserData(user);
    }
  }, [user]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        await updateUser(userData);
        if (userImage) await updateUserImage(userImage);
  
        // Show success toast
        toast.success("Changes saved successfully!");
      } catch (error) {
        // Show error toast
        toast.error("Failed to save changes. Please try again.");
      }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUserImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex-grow w-full p-6 bg-white border border-gray-200 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold text-gray-800">Account Settings</h2>
      <hr className="my-4 border-gray-300" />

      <div className="flex flex-col items-center gap-6">
        {/* Profile Image Section */}
        <div className="relative" onClick={handleImageClick}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleImageChange}
            accept=".jpg, .png, .jpeg, .webp"
          />
          <div className="relative w-40 h-40 overflow-hidden rounded-full shadow-md">
            {imageUrl ? (
              <img src={imageUrl} alt="User Avatar" className="object-cover w-full h-full" />
            ) : user?.image_url ? (
              <img src={`https://${user.image_url}`} alt="User Avatar" className="object-cover w-full h-full" />
            ) : (
              <img src={userImg} alt="Default Avatar" className="object-cover w-full h-full" />
            )}
          </div>
          <p className="bottom-0 left-0 w-full pt-10 font-bold text-center text-green-500 cursor-pointer text-md hover:text-green-700">
            Edit Photo
          </p>
        </div>

        {/* Input Fields */}
        <form onSubmit={handleSubmit} className="flex flex-col w-full max-w-lg gap-6">
          {/* Basic Information */}
          <div>
            <label className="block mb-1 text-sm text-gray-600">Name</label>
            <input
              type="text"
              name="name"
              value={userData?.name || ""}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="John Doe"
            />
          </div>

          <div>
            <label className="block mb-1 text-sm text-gray-600">Email Address</label>
            <input
              type="email"
              name="email"
              value={userData?.email || ""}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="johndoe@example.com"
            />
          </div>

          <div>
            <label className="block mb-1 text-sm text-gray-600">First Name</label>
            <input
              type="text"
              name="first_name"
              value={userData?.first_name || ""}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="John"
            />
          </div>

          <div>
            <label className="block mb-1 text-sm text-gray-600">Last Name</label>
            <input
              type="text"
              name="last_name"
              value={userData?.last_name || ""}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Doe"
            />
          </div>

          {/* Social Links */}
          <div>
            <label className="block mb-1 text-sm text-gray-600">Facebook Link</label>
            <input
              type="text"
              name="facebook_link"
              value={userData?.facebook_link || ""}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="https://facebook.com/username"
            />
          </div>

          <div>
            <label className="block mb-1 text-sm text-gray-600">Twitter Link</label>
            <input
              type="text"
              name="twitter_link"
              value={userData?.twitter_link || ""}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="https://twitter.com/username"
            />
          </div>

          <div>
            <label className="block mb-1 text-sm text-gray-600">LinkedIn Link</label>
            <input
              type="text"
              name="linkedin_link"
              value={userData?.linkedin_link || ""}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="https://linkedin.com/in/username"
            />
          </div>

          <button
            type="submit"
            className="w-full py-2 mt-4 text-white bg-green-500 rounded-lg hover:bg-green-600"
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
}
