import React from "react";
import "./Style.css";
import "../subHeadingContainer/SubHeadingContainer.css";
import { Link } from "react-router-dom";
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { useUser } from "../../Context/UserContext";
import UserIcon from "../Svgs/UserIcon";

const Sidebar = () => {
  const userData = useUser();

  if (!userData) {
    return <p>Loading...</p>;
  }

  const { name, email, image_url, linkedin_link, twitter_link, facebook_link } = userData;

  return (
    <div className="flex flex-col w-full h-auto p-4 bg-white shadow-xl rounded-xl bg-clip-border md:w-64 lg:w-96">
      {/* User Profile Section */}
      <div className="p-4 mb-4 bg-[#5ab963] rounded-t-lg">
        <div className="flex justify-center">
          <div className="flex items-center justify-center w-28 h-28 bg-[#5ab963] border-4 border-white rounded-full">
            {image_url ? (
              <img
                src={`https://${image_url}`}
                alt="User Avatar"
                className="w-full h-full rounded-full"
              />
            ) : (
              <UserIcon /> // Fallback User Icon
            )}
          </div>
        </div>
        <div className="mt-3 text-center">
          <h5 className="pb-1 text-2xl font-bold text-white">{name || "UserName"}</h5>
          <span className="text-lg text-white">{email || "Email"}</span>
        </div>

        {/* Social Links Section */}
        <div className="flex flex-col items-center gap-4 mt-6 mb-4">
          <div className="flex gap-4">
            {/* Twitter */}
            <a
              href={twitter_link || "#"}
              target={twitter_link ? "_blank" : undefined}
              rel={twitter_link ? "noopener noreferrer" : undefined}
              className={`p-2 bg-white rounded-full hover:text-white hover:bg-[#5ab963] ${
                !twitter_link && "opacity-50 cursor-not-allowed"
              }`}
            >
              <FaSquareXTwitter className="w-8 h-8" />
            </a>

            {/* LinkedIn */}
            <a
              href={linkedin_link || "#"}
              target={linkedin_link ? "_blank" : undefined}
              rel={linkedin_link ? "noopener noreferrer" : undefined}
              className={`p-2 bg-white rounded-full hover:text-white hover:bg-[#5ab963] ${
                !linkedin_link && "opacity-50 cursor-not-allowed"
              }`}
            >
              <FaLinkedin className="w-8 h-8" />
            </a>

            {/* Facebook */}
            <a
              href={facebook_link || "#"}
              target={facebook_link ? "_blank" : undefined}
              rel={facebook_link ? "noopener noreferrer" : undefined}
              className={`p-2 bg-white rounded-full hover:text-white hover:bg-[#5ab963] ${
                !facebook_link && "opacity-50 cursor-not-allowed"
              }`}
            >
              <FaFacebookSquare className="w-8 h-8" />
            </a>
          </div>
        </div>
      </div>

      {/* Navigation Section */}
      <nav className="flex flex-col gap-2 font-sans text-base font-normal text-blue-gray-700">
  <Link to="/profile">
    <div
      role="button"
      className="flex justify-center items-center w-full p-3 text-xl font-[500] leading-tight transition-all outline-none bg-[#e7e7e7] hover:bg-[#5ab963] hover:text-[white]"
    >
      Profile
    </div>
  </Link>
  <Link to="/profile-settings">
    <div
      role="button"
      className="flex justify-center items-center w-full p-3 text-xl font-[500] leading-tight transition-all rounded-b-lg outline-none bg-[#e7e7e7] hover:bg-[#5ab963] hover:text-[white]"
    >
      Account Settings
    </div>
  </Link>
</nav>
    </div>
  );
};

export default Sidebar;
