import React from 'react';

function BottomSmallBox() {
  return (
    <div className="w-full text-center  left-0 mt-[3rem]">
      <article className="text-white text-[12px]">
        © 2024 Akiya2.0 KK | Revitalizing Japan’s Akiya Houses and Communities: All rights reserved.
      </article>
    </div>
  );
}

export default BottomSmallBox;