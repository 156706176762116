import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import createSlug from "../../createSlug";
const FirstSOVFullImage = "/images/MainLogo.webp";

// Function to truncate content to 150 characters
const parseContent = (blogContent) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = blogContent;
  const content = tempDiv.textContent || tempDiv.innerText || "";

  // Split content into words
  const words = content.split(/\s+/); // Split by whitespace
  const wordLimit = 28; // Limit to 30 words

  // Truncate and join words
  return words.slice(0, wordLimit).join(" ") + (words.length > wordLimit ? "..." : "");
};

const BlogCard = ({ item }) => {
  const slug = useMemo(() => createSlug(item.blog_heading), [item.blog_heading]);
  const [imageLoaded, setImageLoaded] = useState(false);

  const {
    blog_heading,
    coverImage,
    createdAt,
    blog_content = "",
    serial_number,
  } = item;

  // Memoized date formatting for better performance
  const formattedDate = useMemo(() => {
    return new Date(createdAt).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }, [createdAt]);

  // Memoized content truncation
  const content = useMemo(() => parseContent(blog_content), [blog_content]);

  return (
    <Link
      to={`/blogs/${serial_number}/${slug}`}
      className="flex shadow-xl w-full md:w-[100%] lg:w-[100%] justify-around cursor-pointer"
    >
      <div className="flex flex-col w-full">
        {/* Image Section */}
        <div className="h-[18rem] w-full">
          <img
            loading="lazy"
            src={imageLoaded ? coverImage : FirstSOVFullImage}
            alt="Akiya2.0 blogs"
            className="object-cover w-full h-full"
            onLoad={() => setImageLoaded(true)}
          />
        </div>

        {/* Content Section */}
        <div className="mx-3">
          <p className="mt-2 text-[--medium-sea-green]">{formattedDate}</p>
          <p className="mt-2 text-xl font-bold mb-2">{blog_heading}</p>
          <p className="font-light text-md text-[--dark-grey] mb-10 break-words overflow-hidden">
            {content}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;