import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import formatDate from "../../FormatDate";
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import Loader from "../Loader/Loader";
import QuestionBox from "../QuestionBox/QuestionBox";
import createSlug from "../../createSlug";
import "../subHeadingContainer/SubHeadingContainer.css";

function SingleBlog() {
  const [data, setData] = useState(null);
  const [latestBlogData, setLatestBlogData] = useState([]);
  const { blogId, blogSlug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch single blog data
    axios
      .get(`https://app.akiya2.com/blog/${blogId}`)
      .then((response) => setData(response.data))
      .catch((error) => console.error("Error fetching blog data:", error));

    // Fetch latest blogs
    axios
      .get("https://app.akiya2.com/blogs")
      .then((response) => {
        const sortedData = response.data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 5);
        setLatestBlogData(sortedData);
      })
      .catch((error) => console.error("Error fetching latest blogs:", error));
  }, [blogId]);

  useEffect(() => {
    if (data) {
      const generatedSlug = createSlug(data.blog_heading);
      if (blogSlug !== generatedSlug) {
        navigate(`/blogs/${blogId}/${generatedSlug}`, { replace: true });
      }
    }
  }, [data, blogSlug, blogId, navigate]);

  if (!data) {
    return <Loader big={false} />;
  }

  // Generate the correct slug and URL
  const generatedSlug = createSlug(data.blog_heading);
  const currentUrl = `https://www.akiya2.com/blogs/${blogId}/${generatedSlug}`;

  const links = [
    {
      name: "LinkedIn",
      link: `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(currentUrl)}`,
      icon: <FaLinkedin />,
    },
    {
      name: "Twitter",
      link: `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`,
      icon: <FaSquareXTwitter />,
    },
  ];

  return (
    <div className="font-sans max-w-[1440px] mx-auto flex flex-col">
      {/* Hero Section */}
      <div className="relative w-full">
        <div className="relative w-full h-[400px] md:h-[500px]">
          <div
            className="absolute top-0 left-0 w-full h-full bg-center bg-cover filter blur-xl"
            style={{
              backgroundImage: `url(${data.coverImage})`,
            }}
          ></div>
          <div className="relative z-10 w-full h-full">
            <img
              className="object-cover w-full h-full"
              src={data.coverImage}
              alt={data.blog_heading}
              loading="lazy"
            />
          </div>
        </div>
        <div className="absolute p-3 text-white bg-black bg-opacity-50 rounded-lg bottom-4 left-4">
          <p className="text-sm">{blogSlug}</p>
        </div>
      </div>

      {/* Content Section */}
      <div className="flex flex-col gap-8 px-8 mx-auto mt-10 max-w-7xl md:flex-row">
        {/* Main Blog Content */}
        <div className="w-full md:w-2/3">
          <div>
            <p className="mb-2 text-[18px] subheading">{formatDate(data.createdAt)}</p>
            <h1 className="mb-2 heading">{data.blog_heading}</h1>
            <div className="sm:flex items-center justify-between mb-8">
              <div>
                <p className="font-bold text-[18px] subheading">{data.author}</p>
              </div>
              <div className="flex items-center space-x-4">
                <p className="text-sm">Share on:</p>
                {links.map((link) => (
                  <a
                    key={link.name}
                    href={link.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center bg-white text-[#5ab963]"
                  >
                    {link.icon}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="prose max-w-none">
            <div dangerouslySetInnerHTML={{ __html: data.blog_content }} />
          </div>
        </div>

        {/* Sidebar Section */}
        <div className="w-full md:w-1/3">
          <h3 className="mb-3 font-bold text-[18px] subheading">Latest Blogs</h3>
          <div className="space-y-4">
            {latestBlogData.map((article) => (
              <Link
                key={article.blogId}
                to={`/blogs/${article.serial_number}/${createSlug(article.blog_heading)}`}
                className="text-black hover:text-white flex items-center gap-4 p-2 transition bg-[#e7e7e7] rounded-lg  hover:bg-[--sea-green]"
              >
                <img
                  src={article.coverImage}
                  alt={article.blog_heading}
                  className="object-cover min-w-16 max-w-16 h-16 rounded"
                />
                <div>
                  <p className="font-bold ">{article.blog_heading}</p>
                  <p className="text-sm ">{article.author}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>

      {/* Additional Section */}
      <QuestionBox />

      {/* Call to Action Section */}
      {/* <div className="flex flex-col md:flex-row items-center my-20">
        <div className="md:w-1/2 mb-8 md:mb-0">
          <h1 className="text-3xl font-bold">
            Everyone knows there are over 8 million Akiya in Japan.
          </h1>
          <h2 className="text-2xl font-light text-[#949995]">
            Here's the fuss-free way to actually own one.
          </h2>
        </div>
        <div className="md:w-1/2 flex justify-center md:mr-[8rem]">
          <Link to="/services/how-it-works">
            <button className="py-4 px-8 bg-[#5ab963] rounded text-white text-lg font-bold">
              Our Services
            </button>
          </Link>
        </div>
      </div> */}
    </div>
  );
}

export default SingleBlog;