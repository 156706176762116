import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//500
const ProfileBLogCard = ({ item }) => {
  const { title, imageUrls, date, content, index } = item;
    const navigate=useNavigate();
  return (
    <div className="flex justify-around shadow-2xl cursor-pointer "
    onClick={()=>navigate(`/blogs/${index}/slug`)}>
      <div className="flex flex-col ">
        <div className=" h-[15rem]    ">
          {/* Set a fixed width and height for the image */}
          <img
            loading="lazy"
            src={imageUrls}
            alt="Akiya2.0 blogs"
            className="object-cover w-full h-full"
          />
        </div>

        <div className="mx-3 ">
          <p className="mt-2 text-[--medium-sea-green]">{date}</p>
          <p className="mt-2 mb-2 text-xl font-bold">{title}</p>
          <p className="font-light text-md text-[--dark-grey] mb-10  ">
            {content}
          </p>
        </div>
        
      </div>
    </div>
  );
};

export default ProfileBLogCard;
