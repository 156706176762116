import React from "react";

const UserIcon = () => (
  <svg
    class="svg-icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M703.392304 600.787606c-52.709645 39.404298-118.724638 59.362319-191.392304 59.362319s-132.029985-19.958021-191.392304-59.362319c0 6.652674-6.652674 6.652674-13.305347 6.652674-132.029985 79.32034-224.655672 224.655672-224.655672 382.784608 0 19.958021-13.305347 33.263368-33.263369 33.263368s-33.263368-13.305347-33.263368-33.263368c0-184.73963 99.278361-350.032984 257.407296-436.005997-58.850575-58.850575-91.602199-138.170915-91.602199-224.143928C181.925037 145.335332 327.26037 0 512 0s330.074963 145.335332 330.074963 330.074963c0 85.973013-33.263368 165.293353-92.625688 224.655672 158.64068 85.973013 257.407296 250.754623 257.407297 436.005997 0 19.958021-13.305347 33.263368-33.263369 33.263368s-33.263368-13.305347-33.263368-33.263368c0-158.64068-92.625687-303.976012-231.308346-382.784608 1.023488-0.511744-5.629185-0.511744-5.629185-7.164418z m-191.392304-6.652673c145.335332 0 264.05997-118.724638 264.05997-264.05997S657.335332 66.014993 512 66.014993 247.94003 184.73963 247.94003 330.074963s118.724638 264.05997 264.05997 264.05997z" />
  </svg>
);

export default UserIcon;
