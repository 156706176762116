import React, { useEffect, useRef, useState } from "react";

const japanTravel = "/images/about/japantravel.webp";
const o2ostays = "/images/about/o2ostays.webp";
const suzu = "/images/about/suzu.webp";
const wajima = "/images/about/wajima.webp";
const kominka = "/images/about/kominka.png";
const kominka1 = "/images/about/kominka1.webp";
const imabari = "/images/about/imabari.webp";
const kankensetsu = "/images/about/kankensetsu.webp";

const LogoSlider = () => {
  const logos = [
    { src: japanTravel, link: "https://en.japantravel.com/" },
    { src: o2ostays, link: "https://www.o2ostays.com/" },
    { src: suzu, link: "https://www.city.suzu.lg.jp.e.er.hp.transer.com/" },
    { src: wajima, link: "https://www.city.wajima.ishikawa.jp/top.html" },
    { src: kominka, link: "https://kominka.net/" },
    { src: kominka1, link: "https://kominka.net/" },
    { src: imabari, link: "https://www.city.imabari.ehime.jp/" },
    { src: kankensetsu, link: "https://www.sekikensetsu.com/" },
  ];

  const [visibleLogos, setVisibleLogos] = useState([...logos, ...logos]); // Duplicate logos for seamless looping
  const [translateX, setTranslateX] = useState(0); // Track the translateX value
  const containerRef = useRef(null);

  const handleClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    const totalWidth = containerRef.current.scrollWidth / 2; // Total width of the original logos
    const scrollSpeed = 1.2; // Speed of the scroll (pixels per frame)

    const animation = setInterval(() => {
      setTranslateX((prev) => {
        const nextTranslateX = prev - scrollSpeed; // Incrementally decrease translateX
        return Math.abs(nextTranslateX) >= totalWidth
          ? 0 // Reset translateX when the scroll reaches the end
          : nextTranslateX; // Continue scrolling
      });
    }, 16); // ~60fps for smooth animation

    return () => clearInterval(animation); // Cleanup interval on unmount
  }, []);

  return (
    <div className="relative overflow-hidden w-full bg-white py-4 mb-[5rem]">
      <div
        ref={containerRef}
        className="flex"
        style={{
          transform: `translateX(${translateX}px)`,
          transition: "transform 0.016s linear", // Smooth continuous movement
        }}
      >
        {visibleLogos.map((logo, index) => (
          <img
            key={index}
            src={logo.src}
            alt={`Logo ${index + 1}`}
            className="h-12 mx-4 cursor-pointer"
            onClick={() => handleClick(logo.link)}
          />
        ))}
      </div>
    </div>
  );
};

export default LogoSlider;