import React from "react";
import HeadingForAll from "../HeadingForAll/HeadingForAll";
import Sidebar from "../Sidebar/Sidebar";
import ProfileCardAccountSettings from "../ProfileCard/ProfileCard"
import ProfileAvatar from "../ProfileCard/ProfileAvatar";

export default function ProfileSettingAvatar() {
  return(

    <div className="relative">
  <HeadingForAll bigHeading={"My Profile"} isProfile={true} />

  <div className="flex justify-center">
    <div className="w-full max-w-[1440px] relative">
      <div className="flex flex-col w-full gap-2 mt-10 mb-10 md:flex-row">
        <Sidebar />
           
            
              <div className="w-full mx-auto">
              <ProfileAvatar />
             
            </div>
          </div>
        </div>
      </div>
    </div>
  
  );
}