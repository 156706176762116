import React, { useContext, useState } from "react";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import Modal from "./RegisterAnd Login/Modal";
import { UserContext } from "../../Context/UserContext";
import User from "../Svgs/User"; // Fallback icon
import useWindowDimensions from "../useWindowDimensions";
import Menu from "../Svgs/Menu";

const Nav = () => {
  const { showModal, setShowModal, user, setUser, setIsToken } =
    useContext(UserContext);
  const { width } = useWindowDimensions();
  const [navbarDropDown, setNavbarDropDown] = useState(false);
  const [servicesDropdown, setServicesDropdown] = useState(false);
  const navigate = useNavigate();

  const handleModalOpen = () => {
    if (user) {
      navigate("/profile");
    } else {
      setShowModal(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    setIsToken(false);
    setUser(null);
    navigate("/");
  };

  const navLinks = [
    { to: "/akiya-search", text: "Akiya Search" },
    {
      text: "Our Services",
      dropdown: [
        { to: "/services/how-it-works", text: "How It Works" },
        { to: "/services/what-it-costs", text: "What It Costs" },
      ],
    },
    { to: "/our-projects", text: "Our Projects" },
    { to: "/invest-in-akiya", text: "Invest in Akiya" },
    { to: "/community", text: "Community" },
  ];

  return (
    <div className="relative">
      <div className="navigation-container h-[5rem] w-[100%] bg-[#5ab963] sticky top-0 z-50 flex justify-center">
        <div className="flex h-[100%] w-[100%] pl-[10px] justify-between max-w-[1440px]">
          <div className="logo h-[100%] w-[140px] flex items-center">
            <Link to="/">
              <img src="/images/MainLogo.webp" alt="Akiya2.0 logo" />
            </Link>
          </div>
          {width > 850 ? (
            <div className="flex md:w-[90%] xl:w-[60%] lg:w-[70%]">
              {navLinks.map((link, index) => (
                <div
                  key={index}
                  className="logo h-[100%] w-[16.5%] flex justify-center items-center text-white text-[14px] font-[600] leading-[24px] relative"
                  onMouseEnter={() =>
                    link.text === "Our Services" && setServicesDropdown(true)
                  }
                  onMouseLeave={() =>
                    link.text === "Our Services" && setServicesDropdown(false)
                  }
                >
                  {link.dropdown ? (
                    <div className="relative">
                      <button className="transition-all duration-300 hover:[text-shadow:_1px_2px_2px_rgb(21_12_11_/_40%)]">
                        {link.text}
                      </button>
                      {servicesDropdown && (
                        <div className="absolute left-[-50%] top-[2rem] border-x-2 border-white border-2 bg-[--medium-sea-green] text-center flex flex-col text-white w-[11rem]">
                          {link.dropdown.map((subLink, subIndex) => (
                            <Link
                              key={subIndex}
                              to={subLink.to}
                              className="w-full p-2 hover:bg-[--sea-green]"
                            >
                              {subLink.text}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <Link
                      to={link.to}
                      className="transition-all duration-300 hover:[text-shadow:_1px_2px_2px_rgb(21_12_11_/_40%)]"
                      onClick={() => setNavbarDropDown(false)} // Close dropdown on link click
                    >
                      {link.text}
                    </Link>
                  )}
                </div>
              ))}
              {user ? (
                <div
                  className="w-[12%] h-[3rem] my-3 relative rounded logo flex justify-center items-center text-white text-[14px] font-[600] leading-[24px]"
                  onMouseEnter={() => setNavbarDropDown(true)}
                  onMouseLeave={() => setNavbarDropDown(false)}
                >
                  <div className="flex items-center justify-center mt-1 overflow-hidden bg-white rounded-full w-9 h-9">
                    {/* Display profile image or fallback */}
                    {user.image_url ? (
                      <img
                        src={`https://${user.image_url}`}
                        alt="Profile"
                        className="object-cover w-full h-full"
                      />
                    ) : (
                      <User /> // Fallback User Icon
                    )}
                  </div>
                  {navbarDropDown && (
                    <div className="absolute top-[3rem] border-2 bg-[--medium-sea-green] text-center flex flex-col text-white w-[6rem]">
                      <Link
                        to="/profile"
                        className="w-full p-2 hover:bg-[--sea-green]"
                      >
                        Profile
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="w-full p-2 hover:bg-[--sea-green]"
                      >
                        Logout
                      </button>
                      <Link
                        to="/profile-settings"
                        className="w-full p-2 hover:bg-[--sea-green]"
                      >
                        Settings
                      </Link>
                    </div>
                  )}
                </div>
              ) : (
                <button
                  onClick={handleModalOpen}
                  className="w-[12%] h-[2.5rem] my-5 hover:bg-white hover:text-black ring-2 ring-white ring-inset rounded-md logo flex justify-center items-center text-white text-[14px] font-[600] leading-[24px]"
                >
                  <p>Sign in</p>
                </button>
              )}
            </div>
          ) : (
            <button
              className="mr-4 text-white"
              onClick={() => setNavbarDropDown(!navbarDropDown)}
            >
              <div className="w-10 h-10">
                <Menu />
              </div>
            </button>
          )}
        </div>
      </div>
      <Modal show={showModal} onClose={handleModalClose} />
    </div>
  );
};

export default Nav;
