// src/Components/BlogWriting/BlogWriting.jsx

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { fetchDataWithToken } from "../../Utils/CommonFunctions";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import HeadingForAll from "../HeadingForAll/HeadingForAll";
import "./styles.css";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#FFFFFF",
    borderColor: state.isFocused ? "#5AB963" : "#5AB963",
    "&:hover": {
      borderColor: "#5AB963",
    },
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: "#FFFFFF",
    color: "#333333",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333333",
  }),
};

export default function BlogWriting() {
  const [JoditEditor, setJoditEditor] = useState(null);
  const [editorConfig, setEditorConfig] = useState(null);
  const [author, setAuthor] = useState("");
  const [greeting, setGreeting] = useState("");
  const [header, setHeader] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [userData, setUserData] = useState({});
  const [fullNameInput, setFullNameInput] = useState("");
  const [nameMissing, setNameMissing] = useState(false);
  const editorRef = useRef(null);

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    fetchDataWithToken("https://app.akiya2.com/get-user-data", access_token).then((data) => {
      if (data) {
        setUserData(data);
        if (data.first_name && data.last_name) {
          setAuthor(`${data.first_name} ${data.last_name}`);
        } else {
          setNameMissing(true);
        }
      } else {
        localStorage.removeItem("access_token");
      }
    });
  }, []);

  useEffect(() => {
    function getGreeting() {
      const hour = new Date().getHours();
      if (hour >= 5 && hour < 12) return "Good morning";
      if (hour >= 12 && hour < 18) return "Good afternoon";
      return "Good evening";
    }
    setGreeting(getGreeting());
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      Promise.all([
        import("jodit-react"),
        import("./config").then((module) => module.getEditorConfig()),
      ])
        .then(([joditReactModule, config]) => {
          setJoditEditor(joditReactModule.default);
          setEditorConfig(config);
        })
        .catch((error) => console.error("Error loading editor:", error));
    }
  }, []);

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    setCoverImage(file);

    // Preview logic
    if (coverImagePreview) URL.revokeObjectURL(coverImagePreview);
    setCoverImagePreview(file ? URL.createObjectURL(file) : null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const editorContent = editorRef.current?.value;

    // Validations
    if (!header || !editorContent || !coverImage) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // Check for at least one image in the content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = editorContent;
    if (!tempDiv.querySelector("img")) {
      toast.error("Please include at least one image in your blog content.");
      return;
    }

    let finalAuthor = author;
    if (nameMissing && !fullNameInput.trim()) {
      toast.error("Please provide your full name.");
      return;
    } else if (nameMissing) {
      finalAuthor = fullNameInput.trim();
    }

    const formData = new FormData();
    formData.append("AuthorName", finalAuthor);
    formData.append("BlogHeading", header);
    formData.append("BlogContent", editorContent);
    formData.append("CoverImage", coverImage);

    try {
      await axios.post("https://app.akiya2.com/addBlog", formData);
      toast.success("Blog added successfully!");
      setTimeout(() => (window.location.href = "/"), 2000);
    } catch (error) {
      console.error("Submission error:", error);
      toast.error("Error submitting the blog. Please try again.");
    }
  };

  const firstHeading = `${greeting}, ${userData.first_name || "User"}`;
  const secondHeading = "Ready to write something new?";

  return (
    <div className="blog-writing-container">
      <HeadingForAll smallHeading={firstHeading} bigHeading={secondHeading} />

      <div className="form-container mx-auto my-8 max-w-4xl p-4 bg-white shadow-md rounded">
        <form onSubmit={handleSubmit}>
          {nameMissing && (
            <div className="mb-6">
              <label className="block text-gray-700 font-bold mb-2">
                Your Full Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter your full name"
                value={fullNameInput}
                onChange={(e) => setFullNameInput(e.target.value)}
                className="w-full border border-gray-300 p-3 rounded focus:outline-[#5ab963]"
                required
              />
            </div>
          )}

          <div className="mb-6">
            <label className="block text-gray-700 font-bold mb-2">
              Blog Heading <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter the title of your blog"
              value={header}
              onChange={(e) => setHeader(e.target.value)}
              className="w-full border border-gray-300 p-3 rounded focus:outline-[#5ab963]"
              required
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 font-bold mb-2">
              Cover Image <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleCoverImageChange}
              className="w-full border border-gray-300 p-3 rounded focus:outline-[#5ab963]"
              required
            />
            {coverImagePreview && (
              <div className="mt-4">
                <p className="text-gray-700 font-bold mb-2">Cover Image Preview:</p>
                <img
                  src={coverImagePreview}
                  alt="Cover Preview"
                  className="max-w-full h-auto border border-gray-300 rounded"
                />
              </div>
            )}
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 font-bold mb-2">
              Blog Content <span className="text-red-500">*</span>
            </label>
            {JoditEditor && editorConfig ? (
              <JoditEditor ref={editorRef} config={editorConfig} />
            ) : (
              <p>Loading editor...</p>
            )}
          </div>

          <button
            type="submit"
            className="w-full bg-[#5ab963] hover:bg-[#48944f] text-white font-bold py-3 rounded focus:outline-none"
          >
            Submit Blog
          </button>
        </form>
      </div>
    </div>
  );
}